function fullBleedImages() {
    if( jQuery( "section.image .full-bleed" ).length ) {
        // Reset previous style
        jQuery( "section.image .full-bleed" ).css({ 'left':'' });
        // Apply new style
        jQuery( "section.image .full-bleed" ).each(function() {
            var thisSection = jQuery(this);
            var offset = thisSection.offset().left;
            thisSection.css({ 'left':'-'+offset+'px', 'opacity':1 });
        });
    }
}

jQuery( document ).ready(function() {
    fullBleedImages();
});

jQuery( window ).resize(function() {
    fullBleedImages();
});
