jQuery( document ).ready(function() {

    const $ = jQuery;
    $(".gallery-slider").each(function() {

        $(this).on('init', function(e, f) {
            $(this).append('<div class="slick-counter">'+ parseInt(f.currentSlide + 1, 10) +' / '+ f.slideCount +'</div>');
        });

        $(this).on('afterChange', function(e, f) {
            $(this).find('.slick-counter').html(f.currentSlide + 1 +' / '+f.slideCount);
        });

        $(this).slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            draggable: false,
            dots: false,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            fade: true,
            speed: 900,
        });
    });
});

