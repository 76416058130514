jQuery( document ).ready(function() {

    // Header - Add toggles
    if(jQuery('#menu-main-menu').length) {
        jQuery( ".menu-item-has-children" ).each(function() {
            jQuery(this).prepend('<div class="sub-menu-toggle"></div>');
        });
    }
    
    // Header - Toggle children
    jQuery('#menu-main-menu').on('click', '.sub-menu-toggle', function() {
        jQuery(this).parent().toggleClass('toggled');
    });

    // Header - Reset menu
    jQuery('#menu-main-menu').on('mouseleave', '> .menu-item', function() {
        var thisElem = jQuery(this);
        setTimeout(function() {
            thisElem.find('.toggled').removeClass('toggled');
        }, 500);
    });

    // Header - Toggle menu
    jQuery('header .header-top .menu-toggle').click(function() {
        jQuery(this).toggleClass('active');
        jQuery("header .header-top .menu-bottom").toggle();
    });

    // Footer - Scroll to top
    jQuery('.go-to-top').click(function() {
        jQuery("html, body").animate({ scrollTop: 0 }, 'fast');
    });

    // Header slider
    if(jQuery('.header-slider').length) {
        var headerSlider = tns({
            container: '.header-slider',
            items: 1,
            slideBy: 'page',
            autoplay: true,
            autoplayButtonOutput: false,
            mouseDrag: true,
            controls: false,
            nav: true,
            loop: true,
            autoHeight: true
        });
    }
});

// Prevent Default Event on links where the href is simply a hashtag
jQuery(document).on('click','a[href="#"]',function(e){e.preventDefault();});


(function($){



    $('.featured-posts-slider').slick({
        dots: true,
        arrows: false,
        speed: 1200,
        fade: true,
        cssEase: 'ease-in-out',
        autoplay: true,
        autoplaySpeed: 5000,
    });

    //Open Emergency Popup after 5 seconds
    setTimeout(function(){
		if($('.emergency_popup').length && $('.emergency_popup').html().match(/\w/g)){
			$('.emergency_popup').addClass('active'); 
		}
	},5000);


    //Close popups on clicking close button
    $(document).on('click','.close', function(){
        $(this).closest('.popup').fadeOut();
        return false;
    });

    //Close popups on pressing Escape key
    $(document).on('keydown', function(e){
        if(e.key == 'Escape'){
            $('.close').trigger('click');
        }
    });

    $(".search-wrapper input").on('keyup', function(e) {
        let $url = $(this).data('search_url');
        let $query = $(this).val();
        // console.log($url + '' + $query);
        // console.log(e.key);
        if(e.key == 'Enter') {
            location.replace($url + '' + $query);
        }
    });

 })(jQuery);