(function($){
  $(document).on('click','.popup .close', function(){
    $(this).parent().remove();
    return false;
  });


  $(document).on('click', '.popup_button', function(){
    $(this).next('.popup_content').fadeIn();

    if( $('.popup_shadow').length == 0 ){
      $(this).parent().append('<div class="popup_shadow" style="display: none;"></div>');
      $('.popup_shadow').fadeIn();
    }
  });

  $(document).on('click', '.popup_content .close, .popup_content .prev, .popup_content .next', function(){
    var $signpost = $(this).closest('.signpost');

    if($(this).hasClass('close')){
      $(this).parent().fadeOut(function(){
        $('.popup_shadow').fadeOut(function(){
          $(this).remove();
        });
      });

    } else if($(this).hasClass('prev')){
      if( $signpost.prev().length > 0 ){
        $(this).parent().fadeOut(function(){
          $signpost.prev().children('.popup_button').trigger('click');
        });

      } else {
        $(this).parent().fadeOut(function(){
          $signpost.nextAll().last().children('.popup_button').trigger('click');
        });
      }

    } else if($(this).hasClass('next')){
      if( $signpost.next().length > 0 ){
        $(this).parent().fadeOut(function(){
          $signpost.next().children('.popup_button').trigger('click');
        });

      } else {
        $(this).parent().fadeOut(function(){
          $signpost.prevAll().last().children('.popup_button').trigger('click');
        });
      }
    }
  });

  $(document).keyup(function(e) {
    if (e.key === "Escape") { 
      $('.close').trigger('click');
    }
  });
})(jQuery);