jQuery( document ).ready(function() {
    
    jQuery('.accordion-items').on('click', '.accordion-item', function() {
        var thisItem = jQuery(this);
        if( !thisItem.hasClass('active') ) {
            // Hide active accordion item
            jQuery('.accordion-items .accordion-item.active .content').slideUp().parent().removeClass('active');
            // Show current accordion item
            thisItem.children('.content').slideDown().parent().addClass('active');
        } else {
            // Hide active accordion item
            thisItem.children('.content').slideUp().parent().removeClass('active');
        }
    });

});