jQuery( document ).ready(function() {
    // Slider
    jQuery(".tiny-slider.tiny-slider-events-activities").each(function() {
        var sliderId = jQuery(this).data('slider');
        var currentSlider = jQuery('.tiny-slider.tiny-slider-events-activities.'+sliderId).parents('.events-wrapper');
        var eventsActivitiesSlider = tns({
            container: '.tiny-slider.tiny-slider-events-activities.'+sliderId,
            items: 1,
            slideBy: 1,
            autoplay: true,
            mouseDrag: true,
            controls: false,
            nav: false,
            responsive: {
                1000: {
                  items: 2
                },
                1300: {
                    items: 3
                }
            }
        });
        // Slider controls
        currentSlider.find('.events-nav-prev').on('click', function() {
            eventsActivitiesSlider.goTo('prev');
        });
        currentSlider.find('.events-nav-next').on('click', function() {
            eventsActivitiesSlider.goTo('next');
        });
    });
});
