(function($){

    $(document).on('ready', function () {

        if( $('.js-event-container').length ) {

            var $container = $('.js-event-container');


            var $slider = $('.month-filters-js');

            if ($slider.length) {
                $slider.slick({
                    slidesToShow: 6,
                    arrows: true,
                    infinite: false,
                    fade: false,
                    swipe: true,
                    autoplay: false,
                    initialSlide: 6,
                    autoplaySpeed: 7000,
                    responsive: [
                        {
                            breakpoint: 1100,
                            settings: {
                                centerMode: false,
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                centerMode: false,
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2
                            },
                        }
                    ]
                });
            }

            var pageNumber = 1;
            var perPage;
            var search;
            var sendData;
            var $loadMoreEntries = $('.js-load-entries');
            var $loadMoreEntriesContainer = $('.load-more-container');
            var $categoryTrigger = $('.js-category-item');
            var $searchTrigger = $('#js-list-search');
            var $searchInput = $('#listing-search-term');
            var $tagTrigger = $('.js-tag-item');
            var $container = $('.js-entries-container');
            var $dateSelect = $('.month-filter-js');
            var $weekSelect = $('.week-select-js');

            // load more button
            $loadMoreEntries.on('click', function (e) {
                e.preventDefault();

                loadResults();

                return false;
            });

            // change of date filter
            $dateSelect.on('click', function (e) {

                e.preventDefault();

                $('.month-filter').removeClass('active');
                $(this).addClass('active');

                $('.showing-month').html($(this).data("event-month-display"));
                $('.showing-month').show();

                pageNumber = 0;

                $container.attr('data-page-num', 1);
                $container.attr('data-month', $(this).data("event-month"));
                $container.attr('data-year', $(this).data("event-year"));

                $container.empty();

                loadResults();

                return false;
            });

            // week select
            $weekSelect.on('click', function (e) {

                e.preventDefault();

                pageNumber = 0;
                week = $container.attr('data-week');
                year = $container.attr('data-year');
                if($(this).hasClass('arrow-left')) {
                    if(week == 1) {
                        week = 52;
                        year = +year - +1;
                    } else {
                        week = +week - +1;
                    }
                } else {
                    if(week == 52) {
                        week = 1;
                        year = +year + +1;
                    } else {
                        week = +week + +1;
                    }
                }

                $container.attr('data-page-num', 1);
                $container.attr('data-week', week);
                $container.attr('data-year', year);

                $container.empty();

                loadResults();

                return false;
            });

            function checkNextEventsPage(perPage, pageNumber, category, tags, pinned_exclude_list, month, year, audience, trigger) {
                var data = {};

                sendData = {
                    action: 'event_ajax_handler',
                    offset: pageNumber * perPage,
                    per_page: perPage,
                    search: search,
                    category: category,
                    tags: tags,
                    pinned_exclude_list: pinned_exclude_list,
                    month: month,
                    year: year,
                    audience: audience
                };

                $.ajax({
                    url: ajaxadminurl.ajaxurl,
                    type: 'GET',
                    data: sendData,
                    dataType: 'html',
                    cache: false,
                    timeout: 10000,
                    success: function (posts) {
                    if (posts !== '<div class="nothing-found"><h4>No results found</h4></div>') {
                        trigger.show();
                    }
                    },
                    complete: function () {

                    }
                });
            }

            function loadResults() {

                category = $container.attr('data-category');
                tags = $container.attr('data-tag-list');
                pinned_exclude_list = $container.attr('data-pinned-exclude-list');
                perPage = $container.data('per-page');
                week = $container.attr('data-week');
                month = $container.attr('data-month');
                year = $container.attr('data-year');
                audience = $container.attr('data-audience');
                search = $searchInput.val();

                sendData = {
                    action: 'event_ajax_handler',
                    offset: pageNumber * perPage,
                    per_page: perPage,
                    search: search,
                    category: category,
                    tags: tags,
                    pinned_exclude_list: pinned_exclude_list,
                    week: week,
                    month: month,
                    year: year,
                    audience: audience
                };

                $.ajax({
                    url: ajaxadminurl.ajaxurl,
                    type: 'GET',
                    data: sendData,
                    dataType: 'html',
                    cache: false,
                    timeout: 10000,
                beforeSend: function () {
                    $loadMoreEntriesContainer.hide();
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $loadMoreEntriesContainer.remove();
                },
                success: function (data, textStatus, jqXHR) {
                    var htmlData = $.parseHTML(jqXHR.responseText);

                    $container.append(htmlData);

                    nextPageNumber = parseInt(pageNumber + 1);

                    checkNextEventsPage(perPage, nextPageNumber, category, tags, pinned_exclude_list, month, year, audience, $loadMoreEntriesContainer);

                },
                complete: function () {
                    pageNumber += 1;
                }
                });
            }

            }


    $('.week-select-js').click(function() {
        $('.month-filters-heading, .month-filters, .category-filters-heading, .category-filters').slideUp();
    })

    $('.month-filter-js').click(function() {
        $('.week-filters-heading, .week-filters, .category-filters-heading, .category-filters, .bottom-events-nav-button').slideUp();
    });


    });
})(jQuery);