jQuery( document ).ready(function() {
    
    jQuery('.navigation').find('li.menu-item-has-children').each(function(){
        if(jQuery(this).find('.current_page_item').length){
            jQuery(this).addClass('open'); 
        }
        var toogle = jQuery('<span class="toggle"></span>')
        jQuery(toogle).on('click',function(){
           jQuery(this).parent().toggleClass('open');
        });
        jQuery(this).append(toogle);
    });

    let $ = jQuery;
    $(window).on('scroll', function() {
        let top = $(window).scrollTop();
        let nav = $('.header-top');
        console.log(top);
        if(top > 100) {
            nav.addClass('scrolled');
        } else {
            nav.removeClass('scrolled');
        }
    });
    
});