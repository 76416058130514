function fullBleedVideo() {
    if( jQuery( "section.video .wrapper.full-bleed" ).length ) {
        // Reset previous style
        jQuery( "section.video .wrapper.full-bleed" ).css({ 'left':'' });
        // Apply new style
        jQuery( "section.video .wrapper.full-bleed" ).each(function() {
            var thisSection = jQuery(this);
            var offset = thisSection.offset().left;
            thisSection.css({ 'left':'-'+offset+'px', 'opacity':1 });
        });
    }
}

jQuery( document ).ready(function() {
    
    fullBleedVideo();

    jQuery( "section.video" ).each(function() {
        var thisSection = jQuery(this);
        var checkLazyLoadVideo = window.setInterval(function(){
            if( thisSection.find('.lazy-load-div').length ) {
                var playButtonText = thisSection.children('.wrapper').data('play-button-text');
                thisSection.find('.lazy-load-div').attr('data-before', playButtonText).css({ 'opacity':'1' });
                clearInterval(checkLazyLoadVideo);
            }
        }, 500);
    });

});

jQuery( window ).resize(function() {
    fullBleedVideo();
});
