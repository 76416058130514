(function($){
  $( document ).ready(function() {
    
    if( $('.timeline-component').length == 0 ){
      return;
    }


    var objects = $('.timeline-component .horizontal-container .timeline-item-container'),
    zIndex = objects.length + 100;

    objects.each(function(){
      $(this).css({
        'z-index': zIndex
      });
      zIndex--;
    });

    function staggeredSlickFadeIn() {
      var tSpeed = 1;

      $('.slick-active .timeline-item').each(function(){
        var ele = $(this);
        
        setTimeout(function(){
          ele.css({
            opacity: 1,
            'transform': 'scale(1)'
          })

        }, tSpeed);
        tSpeed += 330;  
      });
    }

    $(window).on('scroll load resize',function(){
      startAnimation = $('.timeline-component').offset().top - ($(window).height() / 4);
		
		  if( $(window).scrollTop() > startAnimation ){
        staggeredSlickFadeIn();
      }
    });

    $('.timeline-component .horizontal-container').on('init', function(event, slick, direction){
      $('.timeline-item').css({opacity:  0});
    });

    $('.timeline-component .horizontal-container').slick({
      slidesToShow: 8,
      dots: true,
      arrows: true,
      draggable: true,
      focusOnSelect: true,
      infinite: false,
      variableWidth: true,
      swipeToSlide: true,
      touchThreshold: 10,
      edgeFriction: 1,

      responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
      staggeredSlickFadeIn();
    });
    
  });

  //Get selected object center point so we can scroll or jump to it upon non-scrolling navigation (desktop use only)

  //Timeline Popups
    $(document).on('click','.timeline-component .popup-link', function(){
        $('.close').trigger('click');

        var theID = $(this).attr('href');

        $(theID).addClass('popup_active');

        var slideno = $(this).closest('.slick-slide').data('slick-index');
        $('.timeline-component .horizontal-container').slick('slickGoTo', slideno - 1);

        if( $(theID).next().length == 0 ){
          $(theID).find('.popup-next').remove();
        }
        
        
        return false;
    });

    //Close Timeline popups
    $(document).on('click','.timeline-component .close', function(){
        $('.timeline-popup-container').removeClass('popup_active');    
        return false;  
    });

    $(document).on('keydown', function(e){
      if(e.key == 'Escape'){
        $('.close').trigger('click');
      }
    });


    //Popup Navigations
    $(document).on('click','.timeline-popup-nav a', function(e){
      e.preventDefault();

      var currentSlide = $(this).closest('.timeline-popup-container'),
      theNext = currentSlide.next().attr('id'),
      thePrev = currentSlide.prev().attr('id');
      

      if($(this).hasClass('popup-next')){
        $('a[href="#'+theNext+'"]').trigger('click');
      }

      if($(this).hasClass('popup-prev')){
        $('a[href="#'+thePrev+'"]').trigger('click');
      }
      

      return false;
    });

})(jQuery);