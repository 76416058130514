(function($) {

    /**FORMS WITH OVERLAYING LABELS */
    //Handle form label positioning depending on state of input
    $('body').on('focus input blur','form input, form textarea',function(){

        //If it's empty (and not in focus) show the label (except when hide label is applied)
        if( (!$(this).val() && !$(this).is(':focus')) || $(this).parent('.ginput_container_consent').length == 1 ){
            $(this).closest('.gfield:not(.hidden_label)').children('.gfield_label').removeClass('move');
        } else {
            //Otherwise hide the label
            $(this).closest('.gfield').children('.gfield_label:not(legend)').addClass('move');
        }
    });

    //Run on document ready in case of pre-populated or cached/saved fields
    $(document).on('gform_post_render', function(event, form_id, current_page){
        $('form input, form textarea').trigger('input');

        //Add a class to select labels so they do not have the dynamic label
        $('form select').each(function(){
            $(this).closest('.gfield').addClass('gfield_select_field');
        });
    });
    /**FORMS WITH OVERLAYING LABELS */

    $(document).on('change','.gfield_select_field select, .select_field select', function(){
       $(this).closest('.gfield').find('.gfield_label').css({
           'z-index': '',
           'zoom': '0.7',
           'top': '-10px',
           'opacity': '0.5',
           'background-color': 'transparent',
       });
    });
})( jQuery );
