
jQuery( document ).ready(function() {

    jQuery(".tiny-slider.tiny-slider-testimonials").each(function() {
        var sliderId = jQuery(this).data('slider');
        var testimonialsSlider = tns({
            container: '.tiny-slider.tiny-slider-testimonials.'+sliderId,
            controlsContainer: '.'+sliderId+"_controls",
            prevButton: '.prev',
            nextButton: '.next',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: false,
            controls: false,
            nav: false,
            loop: false,
        });
        var info = testimonialsSlider.getInfo();
        const total = info.slideCount;
        var currentSlide = 1;
        var slideString = info.displayIndex + "/" + info.slideCount;
        testimonialsSlider.events.on('transitionEnd', function(info){
            var slideString = info.displayIndex + " / " + info.slideCount;
            jQuery(".slideInfo").text(slideString);
            currentSlide = info.displayIndex;
        });
        jQuery(".slider-btn").on("click", function () {
            if(jQuery(this).hasClass("next")) {
                if(currentSlide == total) {
                    testimonialsSlider.goTo('first');
                } else {
                    testimonialsSlider.goTo('next');
                }
            } else {
                if(currentSlide == 1) {
                    testimonialsSlider.goTo('last');
                } else {
                    testimonialsSlider.goTo('prev');
                }
            }
        });

    });



});
