(function($){
	var partials = '/wp-content/themes/northfields/template-parts/partials/';

	//Change text colour depending on the background colour
	function set_text_colour(){
		$('.rickalendar table tr td em').each(function(){

			const rgb = $(this).css('background-color').split(',');
			rgb[0] = rgb[0].replace(/rgb\(/, '');

			const brightness = Math.round(((parseInt(rgb[0]) * 299) +
													(parseInt(rgb[1]) * 587) +
													(parseInt(rgb[2]) * 114)) / 1000);
			const textColour = (brightness > 128) ? 'black' : 'white';
			const backgroundColour = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
			$(this).css('color', textColour);
		});
	}

	$(document).on('click','.rickalendar .previous_month:not(".disabled"),.rickalendar  .next_month', function(e){
		e.preventDefault();
		
		var theDate = $(this).attr('data-date');
		var theCalendar = $('.rickalendar').attr('data-calendar');
		
		$.ajax({
			type: 'POST',
			url: partials+theCalendar+'.php',
			data: 'date='+theDate,
			success: function (data) {
				$('.rickalendar').html(data);
				var theID = $('.rickalendar ul.category_buttons > li > ul > li.active').attr('data-id');
				$('.rickalendar td[data-id="'+theID+'"]').addClass('active');	
				set_text_colour();
			}
			});
	});

	$(document).on('click','.rickalendar table tr td', function(){
		$('.details .calendar_event').removeClass('active');

		$(document).on('click','.rickalendar .details .close', function(e){
			e.preventDefault();
			$('.rickalendar .details').fadeOut();
		});

		$(document).on('keydown', function(event) {
				if (event.key == "Escape") {
						$('.rickalendar .details .close').trigger('click');
				}
		});

		
		var hasEvents = 0;
		$(this).children('em').each(function(){
			$('.details .calendar_event[data-date="'+$(this).attr('data-date')+'"][data-id="'+$(this).attr('data-id')+'"]').addClass('active');
			hasEvents = 1;
		});
		
		if(hasEvents == 1){
			$('.rickalendar .details .dayofweek').html($(this).attr('data-dayofweek')).closest('.details').fadeIn();
			var details = $('.rickalendar .details'),
			detailsTop = details.offset().top + (details.height() / 2) - ( $(window).innerHeight() / 2);
			
			$('html,body').animate({scrollTop: detailsTop}, 500);
		} else {
			$('.rickalendar .details .close').trigger('click');
		}
		
		$('.rickalendar table tr td').removeClass('selected')
		$(this).addClass('selected');
		
	});





	$(document).ready(function(){
		if($('.rickalendar').length){
		var d = new Date();
		var year = d.getFullYear();
		var month = d.getMonth() + 1;
		var theCalendar = $('.rickalendar').attr('data-calendar');
		
		
		$.ajax({
			type: 'POST',
			url: partials+theCalendar+'.php',
			data: 'date='+year+'-'+month+',',
			success: function (data) {
				$('.rickalendar').html(data);
				set_text_colour();
			}
			});
		}
	});
})(jQuery);